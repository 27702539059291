import classNames from 'classnames/bind';
import React from 'react';

import styles from './BlogContainer.module.scss';

interface Props {
  width?: 'narrow' | 'wide';
  className?: string;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const BlogContainer: React.FC<Props> = ({ width = 'narrow', className, children }) => (
  <div className={cx('container', className, width)}>{children}</div>
);
