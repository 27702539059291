import classNames from 'classnames/bind';
import React from 'react';

import styles from './Toggle.module.scss';

interface Props<T> {
  theme: 'white' | 'blue';
  items: T[];
  activeItem: T;
  onClick: (item: T) => void;
}

const cx = classNames.bind(styles);

export const Toggle = <T extends string>({ theme, items, activeItem, onClick }: Props<T>) => (
  <div className={cx('list', theme)}>
    {items.map(item => (
      <button
        key={item}
        className={cx('button', { active: item === activeItem })}
        onClick={() => onClick(item)}
      >
        {item}
      </button>
    ))}
  </div>
);
