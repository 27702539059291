import annaSeleckaPhoto from '@images/People/anna-selecka.jpg';
import katerinaOmelkovaPhoto from '@images/People/katerina-omelkova.jpg';
import lukasForstPhoto from '@images/People/lukas-forst.jpg';
import martinKnakalPhoto from '@images/People/martin-knakal.jpg';
import michalHosnaPhoto from '@images/People/michal-hosna.jpg';
import michalPecanekPhoto from '@images/People/michal-pecanek.jpg';
import prPhoto from '@images/People/pr.png';
import tomasBacikPhoto from '@images/People/tomas-bacik.jpg';
import vojtechStafaPhoto from '@images/People/vojtech-stafa.jpg';

export type IAuthors =
  | 'Katerina'
  | 'Tomas'
  | 'Michal'
  | 'Vojtech'
  | 'Martin'
  | 'Anna'
  | 'Lukas'
  | 'MichalHosna'
  | 'Public Relations';

type IAuthorsData = {
  [key in IAuthors]: { name: string; photo: string; role: string; email?: string };
};

export const authorsData: IAuthorsData = {
  Katerina: {
    name: 'Katerina Omelkova',
    photo: katerinaOmelkovaPhoto,
    role: 'Global Sales & Account Management',
  },
  Tomas: {
    name: 'Tomas Bacik',
    photo: tomasBacikPhoto,
    role: 'Head of Sales & Client Solutions',
  },
  Michal: { name: 'Michal Pecanek', photo: michalPecanekPhoto, role: 'Marketing' },
  Vojtech: {
    name: 'Vojtech Stafa',
    photo: vojtechStafaPhoto,
    role: 'Head of Tech Support',
  },
  Anna: { name: 'Anna Selecka', photo: annaSeleckaPhoto, role: 'Communications' },
  Martin: { name: 'Martin Knakal ', photo: martinKnakalPhoto, role: 'Developer' },
  Lukas: {
    name: 'Lukas Forst',
    photo: lukasForstPhoto,
    role: 'Platform Architect',
  },
  MichalHosna: {
    name: 'Michal Hosna',
    photo: michalHosnaPhoto,
    role: 'Head of Livestream Engineering',
    email: 'michal.hosna@cdn77.com',
  },
  'Public Relations': { name: 'PR team ', photo: prPhoto, role: 'Press release' },
};
