import classNames from 'classnames/bind';
import Prism from 'prismjs';
import React from 'react';

import { CustomScrollbar } from '../CustomScrollbar/CustomScrollbar';

import styles from './Code.module.scss';

Prism.plugins.NormalizeWhitespace?.setDefaults?.({
  'remove-trailing': true,
  'remove-indent': true,
  'left-trim': true,
  'right-trim': true,
  'break-lines': 140,
  indent: 0,
  'tabs-to-spaces': 4,
  'spaces-to-tabs': 4,
});

Prism.manual = true;

export interface Props {
  header?: React.ReactNode;
  scrollY?: boolean;
  language?: string;
  copyEnabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Code: React.FC<Props> = ({ header, language, scrollY, className, children }) => {
  const codeRef = React.createRef<HTMLPreElement>();

  React.useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [language, children]);

  return (
    <div className={cx('wrapper', className, { maxHeight: scrollY })}>
      {header && <div className={styles.header}>{header}</div>}
      <CustomScrollbar className={styles.pre} tag="pre" x="auto" y={scrollY ? 'auto' : undefined}>
        <code
          ref={language ? codeRef : undefined}
          className={cx('code', {
            [`language-${language ?? ''}`]: language,
          })}
        >
          {children}
        </code>
      </CustomScrollbar>
    </div>
  );
};
