import { asText } from '@prismicio/helpers';
import React from 'react';

import { Code } from '@components/Code/Code';
import { Text } from '@components/Text/Text';
import { Toggle } from '@components/Toggle/Toggle';
import type { IBlogCodeSwitcher } from '@prismic';

import styles from './BlogCodeSwitcher.module.scss';

export const BlogCodeSwitcher: React.FC<IBlogCodeSwitcher> = ({ primary, items }) => {
  const [activeTab, setActiveTab] = React.useState(items[0].tab_name);

  const code = items.find(({ tab_name }) => tab_name === activeTab);

  if (!code) return null;

  return (
    <Code
      className={styles.code}
      header={
        <div className={styles.header}>
          <Text color="white" size={18} tag="strong" weight="bold">
            {primary.label}
          </Text>
          <Toggle
            activeItem={activeTab}
            items={items.map(({ tab_name }) => tab_name)}
            theme="white"
            onClick={setActiveTab}
          />
        </div>
      }
      language={code?.format}
    >
      {asText(code?.code)}
    </Code>
  );
};
