import classNames from 'classnames/bind';
import React from 'react';

import styles from './CustomScrollbar.module.scss';

interface Props {
  customRef?: React.LegacyRef<HTMLDivElement>;
  tag?: 'aside' | 'div' | 'span' | 'pre';
  id?: string;
  x?: React.CSSProperties['overflowX'];
  y?: React.CSSProperties['overflowY'];
  style?: React.CSSProperties;
  testId?: string;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const cx = classNames.bind(styles);

export const CustomScrollbar: React.FC<Props> = ({
  tag: Tag = 'div',
  customRef,
  id,
  x,
  y,
  style = {},
  testId,
  className,
  children,
}) => (
  <Tag
    ref={customRef as any}
    className={cx('wrapper', className)}
    data-testid={testId}
    id={id}
    style={{ overflowX: x, overflowY: y, ...style }}
  >
    {children}
  </Tag>
);
