import { asText } from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import type { RichTextField } from '@prismicio/types';
import classNames from 'classnames/bind';
import React from 'react';

import { components } from '../../BlogPost/BlogPost';
import { Text } from '@components/Text/Text';

import styles from './BlogExamples.module.scss';
import AngleDownIcon from '@icons/angleDown.svg';

const cx = classNames.bind(styles);

export type BlogExamples = {
  id: string;
  slice_type: 'examples';
  primary: {
    examples_title: RichTextField;
    text: RichTextField;
    footer_text: RichTextField;
  };
  items: {
    text: RichTextField;
    code: RichTextField;
  }[];
};

export const BlogExamples: React.FC<BlogExamples> = ({ primary, items }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className={styles.wrapper}>
      <Text
        className={cx('examplesTitle', 'mb-3')}
        color="gray900"
        size={34}
        tag="strong"
        weight="bold"
      >
        {asText(primary.examples_title)}
      </Text>
      <div className={cx('primaryTextWrapper', 'mb-5', { isOpen })}>
        <PrismicRichText components={components(false)} field={primary.text} />
      </div>
      {isOpen && (
        <>
          <ol className={styles.orderedList}>
            {items.map((item, index) => (
              <li key={index}>
                <PrismicRichText components={components(false)} field={item.text} />
                <PrismicRichText components={components(false)} field={item.code} />
              </li>
            ))}
          </ol>
          <PrismicRichText components={components(false)} field={primary.footer_text} />
        </>
      )}
      {!isOpen && (
        <button
          className={styles.showMoreButton}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          See Examples <AngleDownIcon className={styles.angleDownIcon} />
        </button>
      )}
    </div>
  );
};
