/* eslint-disable camelcase */
import { asText } from '@prismicio/helpers';
import type { JSXMapSerializer } from '@prismicio/react';
import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames/bind';
import React from 'react';

import { BlogCodeSwitcher } from '../BlogCodeSwitcher/BlogCodeSwitcher';
import { BlogContainer } from '../BlogContainer/BlogContainer';
import { BlogExamples } from '../BlogList/BlogExamples/BlogExamples';
import { Button } from '@components/Button/Button';
import { DateFormat } from '@components/DateFormat/DateFormat';
import { Container } from '@components/Layout/Container/Container';
import { Head } from '@components/Layout/Head';
import { Note } from '@components/Note/Note';
import { BlogAuthor } from '@components/Pages/Blog/BlogAuthor/BlogAuthor';
import { BlogLatestArticles } from '@components/Pages/Blog/BlogLatestArticles/BlogLatestArticles';
import { BlogPostStructuredData } from '@components/Pages/Blog/BlogPostStructuredData/BlogPostStructuredData';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import type { IBlogAuthor } from '@prismic';
import type { IBlogPost } from '@prismic';
import { routes } from '@routes';

import styles from './BlogPost.module.scss';

interface IProps {
  article: IBlogPost;
  latestArticles: IBlogPost[] | null;
}

export const getReadingTime = (sections: IBlogPost['data']['body']) => {
  const wordsPerMinute = 200;

  const value = sections.reduce((combinedTexts, currentSection) => {
    if (currentSection.slice_type === 'section') {
      return `${combinedTexts} ${asText(currentSection.primary.section_title)} ${asText(
        currentSection.primary.section_content,
      )}`;
    }
    if (currentSection.slice_type === 'note') {
      return `${combinedTexts} ${asText(currentSection.primary.note_title)} ${asText(
        currentSection.primary.text,
      )}`;
    }
    if (currentSection.slice_type === 'examples') {
      return `${combinedTexts} ${asText(currentSection.primary.text)} ${currentSection.items
        .map(example => asText(example.text))
        .join(' ')}`;
    }

    return combinedTexts;
  }, '');

  const wordsCount = value.split(' ').length;
  if (wordsCount > 0) {
    const value = Math.ceil(wordsCount / wordsPerMinute);
    return `${value} min read`;
  }

  return null;
};

export const components = (imageWideContainer: boolean): JSXMapSerializer => ({
  image: ({ node }) => (
    <BlogContainer width={imageWideContainer ? 'wide' : 'narrow'}>
      <img
        alt={node.alt ?? ''}
        height={node.dimensions.height}
        src={node.url}
        width={node.dimensions.width}
      />
    </BlogContainer>
  ),
  label: ({ node, children }) => {
    if (node.data?.label === 'blockquote') {
      return <blockquote>{children}</blockquote>;
    }

    return null;
  },
  hyperlink: ({ node, text }) => {
    const url = process.env.NEXT_PUBLIC_APP_URL;
    const articleUrl = node.data.url;

    if (url && articleUrl?.includes(url)) {
      return <Button href={articleUrl.replace(url, '')}>{text}</Button>;
    }

    return (
      <Button key={node.data.url} href={articleUrl} external noIcon>
        {text}
      </Button>
    );
  },
  oListItem: ({ node, children }) => {
    if ((node.spans[0] as any)?.data?.label === 'listIndent') {
      return (
        <ul>
          <li>{children}</li>
        </ul>
      );
    }

    return <li>{children}</li>;
  },
});

const cx = classNames.bind(styles);

export const BlogPost: React.FC<IProps> = ({ article, latestArticles }) => {
  const author = article.data.body.find(slice => slice.slice_type === 'author') as
    | IBlogAuthor
    | undefined;

  return (
    <>
      <BlogPostStructuredData
        author={author?.primary.author_name ?? null}
        coverImage={article.data.cover_image}
        date={article.data.date}
        preview={asText(article.data.preview) ?? ''}
        title={asText(article.data.title) ?? ''}
        uid={article.uid}
      />
      <Head
        canonical={`${routes.blog}/${article.uid}`}
        description={asText(article.data.meta_description) ?? ''}
        imageOg={article.data.cover_image_facebook.url ?? undefined}
        imageTwitter={article.data.cover_image_twitter.url ?? undefined}
        ogUrl={`${routes.blog}/${article.uid}`}
        title={asText(article.data.meta_title) ?? ''}
      />
      <article>
        <Section
          className={styles.headSection}
          padding={{ top: 120, bottom: 150 }}
          tag="header"
          theme="tiles"
        >
          {article.data.isolated_article !== true && (
            <div className={styles.backButtonWrapper}>
              <Button
                href={routes.blog}
                iconPosition="before"
                iconSize={14}
                variant="primary"
                withArrow
              >
                Blog overview
              </Button>
            </div>
          )}
          <div className={styles.headerContent}>
            <div className={styles.headerInfo}>
              <DateFormat date={article.data.date} /> ·{' '}
              <span className={styles.readingTime}>{getReadingTime(article.data.body)}</span>
            </div>
            <Text
              align="center"
              className={styles.title}
              color="gray900"
              data-testid="blog-detail-main-title"
              size={44}
              tag="h1"
              weight="bold"
            >
              {asText(article.data.title)}
            </Text>
          </div>
        </Section>
        <Container>
          <div className={styles.content}>
            <figure className={styles.media}>
              {article.data.cover_image?.url && (
                <img
                  alt={article.data.cover_image.alt ?? ''}
                  className={styles.mainImage}
                  height={420}
                  src={article.data.cover_image.url}
                  width={730}
                />
              )}
            </figure>
            <main className={styles}>
              {article.data.body.map((slice, index) => {
                if (slice.slice_type === 'section') {
                  const { section_id, section_title, section_content, image_wide_container } =
                    slice.primary;

                  return (
                    <section key={slice.id} className={styles.section}>
                      <div className={styles.scrollAnchor} id={section_id ?? undefined} />
                      {asText(section_title) && (
                        <BlogContainer>
                          <Text
                            align="left"
                            className={cx('subtitle', 'mb-4', { firstSubtitle: index === 0 })}
                            color="gray900"
                            size={34}
                            tag="h2"
                            weight="bold"
                          >
                            {asText(section_title)}
                          </Text>
                        </BlogContainer>
                      )}
                      <PrismicRichText
                        components={components(image_wide_container)}
                        field={section_content}
                      />
                    </section>
                  );
                }

                if (slice.slice_type === 'player') {
                  return (
                    <BlogContainer>
                      <div key="video" className={styles.videoWrapper}>
                        <iframe
                          className={styles.videoIFrame}
                          frameBorder="0"
                          src={slice.primary.embed.url}
                          title="Video"
                          allowFullScreen
                        />
                      </div>
                    </BlogContainer>
                  );
                }

                if (slice.slice_type === 'code_switcher') {
                  return (
                    <BlogContainer width={slice.primary.wide_container ? 'wide' : 'narrow'}>
                      <BlogCodeSwitcher key={slice.id} {...slice} />
                    </BlogContainer>
                  );
                }

                if (slice.slice_type === 'author') {
                  return (
                    <BlogContainer>
                      <BlogAuthor key={slice.id} author={slice.primary.author_name} />
                    </BlogContainer>
                  );
                }

                if (slice.slice_type === 'note') {
                  return (
                    <BlogContainer>
                      <Note key="note" renderAsParagraph={false}>
                        {asText(slice.primary.note_title) && (
                          <strong className={styles.noteTitle}>
                            {asText(slice.primary.note_title)}
                          </strong>
                        )}
                        {asText(slice.primary.text) && (
                          <PrismicRichText
                            components={components(false)}
                            field={slice.primary.text}
                          />
                        )}
                      </Note>
                    </BlogContainer>
                  );
                }

                if (slice.slice_type === 'examples') {
                  return (
                    <BlogContainer>
                      <BlogExamples key="examples" {...slice} />
                    </BlogContainer>
                  );
                }

                return null;
              })}
            </main>
          </div>
          <BlogContainer>
            <footer className={styles.footer}>
              {latestArticles && <BlogLatestArticles articles={latestArticles} />}
            </footer>
          </BlogContainer>
        </Container>
      </article>
    </>
  );
};
